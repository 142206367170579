import styled, { css } from 'styled-components';

import { Avatar } from "antd";
import Button from "components/Button";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  min-height: 172px;
  border-radius: 5px 5px 0px 0px;
  border-top: 3px solid;
  padding: 10px;
  background-color: #fff;

  ${({ $color }) => $color && css`
    border-top-color: ${$color};
  `}
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-center;
  flex-direction: row;
  margin-top: 5px;

  ${({ $secondary }) => !$secondary && css`
    flex-wrap: wrap;
  `}

  ${({ $secondary }) => $secondary && css`
    background: var(--Color-4, #F5F5F5);
    padding: 6px 0;
  `}

  ${({ $col }) => $col && css`
    flex-direction: column;
  `}

  @media screen and (max-width: 1256px) {
    padding-left: 5px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;

  ${({ $align }) => $align && css`
    justify-content: ${$align};
  `}

  ${({ $justify }) => $justify && css`
    align-items: ${$justify};
  `}

  ${({ $secondary }) => $secondary && css`
    gap: 0;
  `}

  ${({ $width }) => $width && css`
    width: ${$width};
  `}

  ${({ $ml }) => $ml && css`
    margin-left: ${$ml};
  `}

  ${({ $mr }) => $mr && css`
    margin-right: ${$mr};
  `}

  ${({ $pr }) => $pr && css`
    padding-right: ${$pr};
  `}
`;

export const Title = styled.span`
  color: var(--Input-header, #737373);
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;

  ${({ $secondary }) => $secondary && css`
    color: var(--Color-2, #171717);
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  `}
  
  ${({ $medium }) => $medium && css`
    font-size: 14px;
  `}
`;

export const Heading = styled.span`
  color: var(--Color-2, #171717);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  > span {
    font-weight: 400;
    color: var(--Neutral-400, var(--Placeholder, #A3A3A3));
  }
`;

export const Text = styled.p`
  color: var(--Generic-Black, #000);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  word-break: break-word;

  ${({ $line }) => $line && css`
    @media screen and (max-width: 744px) {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5;
      max-height: calc(1.5em * 3);
    }
  `}

  ${({ $area }) => $area && css`
    display: initial;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #525252;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  ${({ $category }) => $category && css`
    display: initial;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: #171717;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Circle = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #CAE4F1;
  color: #1689CA;
  font-family: Inter;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
`;

export const PopoverToggle = styled.div`
  display: flex;
  width: 134px;
  height: 36px;
  padding: 0;
  border: none;
  background: none;
  flex-direction: row;

  @media screen and (max-width: 1256px) {
    flex-direction: column;
    height: 90px;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;

  }
  
`;
export const GoalActionWrap = styled.div`

  width: 100%;

  @media screen and (max-width: 540px) {
    justify-content: center;
    align-items: center;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  flex-direction: row;

  @media screen and (max-width: 743px) {
    flex-direction: column;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 17vw;
  padding-right: 20px;
  overflow: hidden;

  @media screen and (max-width: 1256px) {
    width: 100%;
  }

`;



export const MidSection = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  gap: 12px;
  flex-direction: row;

  @media screen and (max-width: 743px) {
    padding: 0rem;
    width: 100%;
    white-space: pre-wrap;
    flex-direction: column;

  }
`;

export const Input = styled.input`
  width: 20rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  background-color: white;
  padding: 0.56rem 0.5rem;
  border: none;
`;

export const WrapAvatar = styled.div`
  width: 24px;
  margin-right: 12px;
`;

export const IdAvatar = styled(Avatar)`
  background-color: #e7f1f6 !important;

  .ant-avatar-string {
    color: #1193ea !important;
    font-family: "Inter" !important ;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    vertical-align: middle !important;
  }
`;

export const WrapHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  
  @media screen and (max-width: 743px) {
    width: 100%;
  }

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 90%;
  }

`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  padding-top: 6px;
  overflow: hidden;

  @media screen and (max-width: 743px) {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;