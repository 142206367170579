import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  visibility: hidden;
  pointer-events: none;
  width: 0;
  transition: all .3s;

  ${({ $width }) => $width &&`
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    width: ${$width}%;
  `};

  ${({ $active }) => $active &&`
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    width: 292px;

    @media screen and (max-width: 1334px) {
      width: 220px;
    }
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  border-right: 1px solid #C1CAD1;
  width: 100%;
  background-color: #fff;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 4px 6px 14px;
  margin-bottom: 8px;
`;

/* export const Hr = styled.div`
  height: 1px;
  background-color: #8E97A3;
`;
 */
export const Title = styled.span`
  color: var(--fill-button-prussian-blue, #112538);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
`;

export const Toggler = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  background: transparent;
  padding: 0;
  border: none;

  /* transform: translateX(0px);
  transition: transform .3s;

  :hover {
    transform: translateX(-2px);
  } */
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 5px 10px 5px 6px;
  overflow-y: auto;

  ::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important ;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #D7DADE;
    border-radius: 100px;
  }

  ${({ $secondary }) => $secondary &&`
    padding: 0;
    gap: 0;
  `};
`;
