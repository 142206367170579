import React, { useCallback, useEffect, useRef, useState } from "react";
import { Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactSwitch from "react-switch";
import { Dropdown } from "elements";
import ModalContainer from "react-modal-promise";
import _, { isArray } from "lodash";
import useDeviceType from "../../../Hooks/ResponsiveHook";

import plusIcon from "assets/images/journeys/plus.svg";

import missionIcon from "assets/images/journeys/mission.svg";
import missionIconActive from "assets/images/journeys/mission_active.svg";

import { ReactComponent as Trash } from "assets/images/priorities/trash.svg";

import { ListSubdomains } from "Redux/ListSubdomains/action";
import { ListAreas, CreateArea, DeleteArea, CreateCategory, DeleteCategory } from "Redux/ListAreas/action";

import {
  RetrieveMasterPlanDetails,
  RetrieveMasterPlanDetailsNull,
} from "Redux/RetrieveMasterPlan/action";
import { UpdateUserArea } from "Redux/UpdateUserArea/action";
import { DeleteUserArea } from "Redux/DeleteUserArea/action";
import { UpdateGoal } from "Redux/UpdateGoal/action";
import { UpdateLastStep } from "Redux/UpdateLastStep/action";
import { GenerateAreaCategories } from "Redux/GenerateAreaCategory/action";
import { setIsDisabledStep, SetSelectedGoalAction } from "Redux/Global/action";

import MissionStatement from "./MissionStatment";
import SuggestingTooltip from './SuggestingTooltip';
import CustomSelect, { getOptionWithLabel } from './CustomSelect';
import PlanShortcut from "components/PlanShortcut";
import LockedOver from "components/LockedOver/LockedOver";
import { confirmModal } from "./ConfirmModal";

import * as Styles from "./styled.js";
import StepLayout from "../StepLayout";

import Notify from "utils/notify";
import { PRIORITIZE_PATH } from "utils/routes";
import { getCookie } from "utils/StorageVariables";
import { PrioritizeAreaAndCategories } from "Redux/PrioritizeAreaAndCategories/action";

import cc from "classnames";
import styled from "./styled.module.scss";

import { track } from 'utils/amplitude';

const Mission = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const screenSizes = useDeviceType();
  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  const searchParams = new URLSearchParams(location.search);
  const missionStatementId = searchParams.get('mission-statement');

  const [isDisabled, setIsDisabled] = useState(false);
  const [isSuggestMissionStatement, setIsSuggestMissionStatement] = useState(0);

  const [areasAndCategories, setAreasAndCategories] = useState([]);
  const [focusedSelect, setFocusedSelect] = useState(null);

  const [missionStatmentGoal, setMissionStatementGoal] = useState(null);
  const [isShow, setisShow] = useState(false);

  const [openedDropdown, setOpenedDropdown] = useState({
    type: null,
    id: null,
  });
  
  const $layoutRef = useRef(null);
  const currentRef = useRef(null);

  const { masterPlanDetails } = useSelector((state) => state.RetrieveMasterPlanDetailsReducer);
  const { SelectedClient } = useSelector((state) => state.globalReducer);

  const { data: subdomains } = useSelector((state) => state.listSubdomainsReducer);
  const { data: predefinedAreas, customData: customAreas } = useSelector((state) => state.listAreaReducer);

  useEffect(() => {
    const area = areasAndCategories.find(area => area.goal.id === missionStatementId);

    if (area) {
      setMissionStatementGoal({
        category: area.goal.category_name,
        area: area.name,
        priority: area.goal.priority,
        goal: area.goal.id,
        hasMissionStatement: area.goal.has_mission_statement,
      });
      setisShow(true);
    }
  }, [missionStatementId, areasAndCategories]);

  useEffect(() => {
    const maxLength = 60;
    let currentInputField = null;

    const handleInput = (event) => {
      if (event.target.value.length > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
      }
    };

    if (openedDropdown?.id) {
      const dropdownEl = document.querySelector(
        `[data-select_id="${openedDropdown.id}"]`
      );
      const inputEl = dropdownEl?.querySelector(
        ".ant-select-selection-search-input"
      );

      if (inputEl) {
        inputEl.setAttribute("maxLength", maxLength);
        inputEl.addEventListener("input", handleInput);
        currentInputField = inputEl; // Keep track of the current input field
      }
    }

    // Cleanup function to remove event listener
    return () => {
      if (currentInputField) {
        currentInputField.removeEventListener("input", handleInput);
      }
    };
  }, [openedDropdown?.id]);

  useEffect(() => {
    if (isSuggestMissionStatement === 1 && $layoutRef && $layoutRef.current) {
      if (typeof $layoutRef.current.scrollTo === 'function') {
        $layoutRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        $layoutRef.current.scrollTop = 0;
      }
    }
  }, [isSuggestMissionStatement]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectParam = queryParams.get("redirect");

    if (redirectParam && isDisabled) {
      queryParams.delete("redirect");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.replaceState(null, "", newUrl);
      handleFieldsValidation();
      return;
    } else if (redirectParam) {
      // Remove the query param after processing to avoid processing again after a refresh
      queryParams.delete("redirect");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.replaceState(null, "", newUrl);
      navigate(redirectParam, { replace: true });
    }
  }, [location.search, navigate, isDisabled]);

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");

    dispatch(RetrieveMasterPlanDetails(MasterPlanId));

    return () => {
      dispatch(RetrieveMasterPlanDetailsNull(null));
      setAreasAndCategories([]);
    };
  }, []);

  const userData = JSON.parse(getCookie("userData"));

  useEffect(() => {
    if (!masterPlanDetails) return;

    if (!masterPlanDetails?.areas?.length) {
      dispatch(GenerateAreaCategories(masterPlanDetails?.areas?.length + 1));
    } else {
      if (areasAndCategories.length < masterPlanDetails?.areas?.length) {
        setTimeout(() => {
          focusNextEmpty();
        }, 50);
      }

      setAreasAndCategories(handleFormatArrayByKey(masterPlanDetails?.areas));
    }

    return () => {
      setAreasAndCategories([]);
    };
  }, [masterPlanDetails]);

  useEffect(() => {
    if (!areasAndCategories) return;

    let emptyAreas = areasAndCategories?.filter(
      (item) =>
        (!item?.name || !item?.goal?.category_name) &&
        !(item?.is_private && SelectedClient)
    );

    setIsDisabled(emptyAreas?.length > 0);
    dispatch(setIsDisabledStep(emptyAreas?.length > 0));

    return;
  }, [areasAndCategories, SelectedClient]);

  useEffect(() => {
    let domainId = getCookie("domainId");

    dispatch(ListSubdomains(domainId, data => {
      if (data && Array.isArray(data) && data.length) {
        const subdomainId = data[0].id;

        dispatch(ListAreas(subdomainId));
        dispatch(ListAreas(subdomainId, true));
      }
    }));    
  }, []);

  const subdomainData = isArray(subdomains) && subdomains.length ? subdomains[0] : {};

  const handleFormatArrayByKey = (orderedArr) =>
    orderedArr?.sort(function (a, b) {
      return a?.goal?.priority - b?.goal?.priority;
    });

  const { loading } = useSelector(
    (state) => state.generateAreaCategoriesReducer
  );

  const groupedAreas = [
    {
      label: subdomainData?.name,
      options: predefinedAreas?.map((area) => {
        return {
          label: area?.name,
          value: area?.id,
        };
      }) || [],
    },
    {
      label: "Custom Areas",
      options: customAreas?.map((area) => {
        return {
          label: area?.name,
          value: area?.id,
          custom: true
        };
      }) || []
    }
  ];

  const deleteRow = (id) => {
    let MasterPlanId = getCookie("MasterPlanId");
    let payloads = [
      ...masterPlanDetails?.areas.filter((area) => area.id !== id),
    ].map((item, i) => {
      return {
        goal: item?.goal?.id,
        priority: i + 1,
      };
    });

    // If the remaining areas list has only one item and both area and category are empty, then call the API to generate a new area
    let remainingAreas = masterPlanDetails?.areas.filter((area) => area.id !== id)

    if (remainingAreas.length === 1 && !remainingAreas[0]?.name && !remainingAreas[0]?.goal?.category_name && !SelectedClient) {
      const stepFormData = new FormData();
      stepFormData.append("viewed_masterplan_screen", false);
      
      dispatch(UpdateLastStep(stepFormData));
    }

    dispatch(DeleteUserArea(id));
    dispatch(PrioritizeAreaAndCategories(payloads, MasterPlanId));
    dispatch(SetSelectedGoalAction(null));
  };

  const handleSelectArea = async (area, uId, i) => {
    // Check if mission statement is available
    if (areasAndCategories[i]?.goal?.has_mission_statement) {
      const modal = await confirmModal({ id: areasAndCategories[i]?.goal?.id });
      if (!modal) return;
    }

    let foundArea = predefinedAreas.find((item) => item?.id === area?.value);
    let areasAndCategoriesCopy = [...areasAndCategories];
    let findedObj = areasAndCategoriesCopy?.find((item) => item?.id === uId);

    if (!foundArea) {
      if (!area.label) return;
      findedObj.name = area?.label;
      findedObj.is_custom = true;
    } else {
      findedObj.name = foundArea?.name;
      findedObj.color = foundArea?.color;
      findedObj.is_custom = false;
    }

    // Amplitude
    track("Goal Area Selected", {
      goal_area: area?.name || foundArea?.name,
      goal_id: areasAndCategories[i]?.goal?.id
    });

    // empty previous category value on area change
    dispatch(UpdateGoal(findedObj?.goal?.id, { category_name: "" }, () => {}, false));

    findedObj.goal.category_name = "";
    findedObj.goal.is_custom = false;

    let { name, color, is_custom } = findedObj;
    handleAreaUpdate({ name, color, is_custom }, uId);
    areasAndCategoriesCopy[i] = findedObj;
    setAreasAndCategories(areasAndCategoriesCopy);
  };

  const handleCreateArea = (value, callback) => {
    const existingArea = [...predefinedAreas, ...customAreas].find(
      (item) => item.name === value
    );

    if (existingArea) {
      callback(null);
      return;
    }

    const formData = new FormData();

    formData.append('name', value);
    formData.append('subdomain', subdomainData?.id);

    dispatch(CreateArea(formData, callback));
  };

  const handleDeleteArea = (value) => {
    dispatch(DeleteArea(value));
  }

  const handleSelectCategory = async (category, uId, i, isCustom) => {
    // Check if mission statement is available
    if (areasAndCategories[i]?.goal?.has_mission_statement) {
      if (isCustom)
        return;

      const modal = await confirmModal({ id: areasAndCategories[i]?.goal?.id });
      if (!modal) return;
    }

    let areasAndCategoriesCopy = [...areasAndCategories];
    let findedObj = areasAndCategoriesCopy?.find((item) => item?.id === uId);

    if (!category?.label)
      return;

    findedObj.goal.category_name = category?.label;
    findedObj.goal.is_custom = isCustom;

    let { category_name } = findedObj?.goal;

    // Amplitude
    track("Goal Category Selected", {
      goal_category: category_name,
      goal_id: areasAndCategories[i]?.goal?.id
    });

    handleCategoryUpdate(
      { category_name, is_custom: isCustom },
      findedObj.goal.id
    );

    areasAndCategoriesCopy[i] = findedObj;
    setAreasAndCategories(areasAndCategoriesCopy);
  };

  const handleCreateCategory = (areaId, areaCategories) => (value, callback) => {
    const existingCategory = [...areaCategories?.categories].find(
      (item) => item.name === value
    );

    if (existingCategory) {
      callback(null);
      return;
    }

    const formData = new FormData();

    formData.append('name', value);
    formData.append('area', areaId);

    dispatch(CreateCategory(formData, callback));
  };

  const handleDeleteCategory = (areaId) => (value) => {
    dispatch(DeleteCategory(value, areaId));
  }

  const handleDropdownVisibleChange = (id, type) => (open) => {
    if (open) {
      setOpenedDropdown({ type, id });
    } else if (openedDropdown.type === type && openedDropdown.id === id) {
      setOpenedDropdown({ type: null, id: null });

      if (type === "area") {
        // Debounce to ensure this doesn't immediately reopen
        setTimeout(() => {
          const $dropdown = document.querySelector(`[data-select_id=${id}]`);

          if ($dropdown && !$dropdown.classList.contains("mission_dropdown--empty")) {
            focusNextEmpty();
          }
        }, 250);
      }
    }
  };

  const focusNextEmpty = () => {
    const missionDropdown = document.querySelectorAll(
      ".mission_dropdown--empty"
    );

    if (missionDropdown.length) {
      const antSelect = missionDropdown[0].querySelector(
        ".ant-select-selector"
      );

      if (antSelect) {
        const focusId = missionDropdown[0].getAttribute("data-select_id");

        setFocusedSelect(focusId);
      }
    }
  };

  const handleAddMore = () => {
    setIsDisabled(true);

    dispatch(
      GenerateAreaCategories(masterPlanDetails?.areas?.length + 1, (data) => {
        let MasterPlanId = getCookie("MasterPlanId");
        let payloads = [...data.areas]
          .sort((a, b) => a.goal?.priority - b.goal?.priority)
          .map((item, i) => {
            return {
              goal: item?.goal?.id,
              priority: i + 1,
            };
          });

        // Amplitude
        track("Goal Created", {
          goal_id: item?.goal?.id
        });

        dispatch(PrioritizeAreaAndCategories(payloads, MasterPlanId));
        focusNextEmpty();
      })
    );
  };

  const handleAreaUpdate = useCallback(
    _.debounce(
      (updatedArea, areaId) =>
        dispatch(UpdateUserArea(areaId, { ...updatedArea })),
      500
    ),
    []
  );

  /* const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIndexView(null);
      }
    }
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  } */

  const handleCategoryUpdate = useCallback(
    _.debounce(
      (updatedGoal, goalId) => dispatch(UpdateGoal(goalId, { ...updatedGoal }, () => {}, false)),
      500
    ),
    []
  );

  /* useEffect(() => {
    if (areasAndCategories?.length === 1) return setDltBtnDisable(true);
    return setDltBtnDisable(false);
  }, [areasAndCategories?.length]); */

  useEffect(() => {
    if (focusedSelect) {
      const antSelect = document.querySelector(
        `.mission_dropdown--empty[data-select_id="${focusedSelect}"]`
      );

      if (antSelect) {
        setTimeout(() => {
          const selector = antSelect.querySelector(".ant-select-selector");

          if (currentRef && currentRef.current) {
            currentRef.current.open();
          }

          if (selector) {
            selector.click();
          }

          setFocusedSelect(null);
        }, 50);
      }
    }
  }, [focusedSelect]);

  const handleNext = () => {
    if (isDisabled) {
      handleFieldsValidation();
      return;
    }

    // Check if there are no mission statements
    const hasMissionStatements = areasAndCategories.some(area => area?.goal?.has_mission_statement);

    if (!hasMissionStatements && isSuggestMissionStatement === 0) {
      setIsSuggestMissionStatement(1);
      return;
    }

    const stepFormData = new FormData();
    stepFormData.append("step", 1);
    dispatch(UpdateLastStep(stepFormData));
    navigate(PRIORITIZE_PATH);
  };

  const hanlePvt = ({ is_private, id }) => {
    const pvtFormData = new FormData();
    pvtFormData.append("is_private", !is_private);
    dispatch(UpdateUserArea(id, pvtFormData));
  };

  const handleFieldsValidation = () => {
    Notify.error("Complete all fields to proceed.");

    const missionDropdown = document.querySelectorAll(
      ".mission_dropdown--empty"
    );

    if (missionDropdown.length) {
      const antSelect = missionDropdown[0].querySelector(
        ".ant-select-selector"
      );

      if (antSelect) {
        const focusId = missionDropdown[0].getAttribute("data-select_id");

        setFocusedSelect(focusId);
      }
    }
  };

  /* const capitalize = (str) =>
    str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    }); */

  const handleNavigate = (step) => {
    if (isDisabled) {
      handleFieldsValidation();
      return;
    }

    navigate(step);
  };

  const HeaderContent = () => {
    return (
      <>
        <Styles.TableHeadCol $width={isTablet ? "40vw" : "30vw"} $ml={"24px"} $pl={"22px"}>
          Areas
        </Styles.TableHeadCol>
        <Styles.TableHeadCol $ml={isTablet ?  "20px" : null} >Categories</Styles.TableHeadCol>

        {isTablet &&
          <Styles.ActionsRow $right>
            <Styles.AddButton
              type="button"
              disabled={isDisabled || loading}
              onClick={handleAddMore}
            >
              Add More
              <img src={plusIcon} alt="+" />
            </Styles.AddButton>
          </Styles.ActionsRow>
        }
      </>
    );
  };

  return (
    <StepLayout
      title="1. Select Your Areas & Categories"
      header={isMobile ? null : <HeaderContent />}
      isMissionStatements
      isNextDisabled={loading}
      // nextPageTooltip="Please Select Your Areas & Categories"
      ref={$layoutRef}
      onNext={handleNext}
      onNavigate={handleNavigate}
    >
      <Styles.TableContainer>
        {areasAndCategories?.map((areaCategory, i) => {
          const isLocked = areaCategory?.is_private && userData?.id !== areaCategory?.user;
          const firstItemWithoutMission = areasAndCategories.findIndex(area => !area?.goal?.has_mission_statement);

          const predefinedCategories = predefinedAreas
            ?.find((area) => area?.name === areaCategory?.name)
            ?.categories?.filter(item => !item.custom).map((item) => {
              return {
                label: item?.name,
                value: item?.id,
              };
            }) || [];
          
          const customCategories = customAreas
            ?.find((area) => area?.name === areaCategory?.name)
            ?.categories?.filter(item => item.custom).map((item) => {
              return {
                label: item?.name,
                value: item?.id,
                custom: true
              };
            }) || [];

          return (
            <Styles.Row key={i} border={areaCategory?.color}>
              {isLocked && (
                <LockedOver width="40px" display="row" index={i + 1} />
              )}

              <Styles.Count>{i + 1}</Styles.Count>
              <Styles.ItemContent>
                {isMobile && (
                  <Styles.TableHeadCol $width="30vw" $mr={"15px"}>
                    Areas
                  </Styles.TableHeadCol>
                )}

                <Styles.LeftSide>
                  <CustomSelect
                    type="area"
                    data-select_id={`area-${i}`}
                    ref={`area-${i}` === focusedSelect ? currentRef : null}
                    className={cc(styled.dropdown, {
                      [styled.empty]: !areaCategory?.name,
                      "mission_dropdown--empty": !areaCategory?.name,
                    })}
                    options={groupedAreas}
                    disabled={isLocked}
                    value={getOptionWithLabel(areaCategory?.name, predefinedAreas, customAreas) || undefined}
                    placeholder="Select Area or Enter Your Own"
                    showAction={
                      focusedSelect === `area-${i}` &&
                      openedDropdown.type !== "area"
                        ? ["focus"]
                        : []
                    }
                    autoFocus={
                      focusedSelect === `area-${i}` &&
                      openedDropdown.type !== "area"
                    }
                    onDropdownVisibleChange={handleDropdownVisibleChange(
                      `area-${i}`,
                      "area"
                    )}
                    onSelect={value => handleSelectArea(value, areaCategory?.id, i)}
                    onCreate={handleCreateArea}
                    onDelete={handleDeleteArea}
                  />
                </Styles.LeftSide>

                {isMobile && (
                  <Styles.TableHeadCol $mt={"5px"}>Categories</Styles.TableHeadCol>
                )}

                <Styles.RightSide>
                  <CustomSelect
                    type="category"
                    data-select_id={`cat-${i}`}
                    ref={`cat-${i}` === focusedSelect ? currentRef : null}
                    title={areaCategory?.name ? "" : "Select Area first"}
                    options={[
                      ...(predefinedCategories.length ? [{
                        label: `${areaCategory?.name} Categories`,
                        options: predefinedCategories
                      }] : []),
                      ...(customCategories.length ? [{
                        label: "Custom Categories",
                        options: customCategories
                      }] : [])
                    ]}
                    value={getOptionWithLabel(areaCategory?.goal?.category_name, [...predefinedAreas, ...customAreas]?.find((area) => area?.name === areaCategory?.name)?.categories || [], []) || undefined}
                    disabled={isLocked || !areaCategory?.name}
                    className={cc(styled.dropdown, {
                      [styled.empty]: !areaCategory?.goal?.category_name,
                      "mission_dropdown--empty": !areaCategory?.goal?.category_name,
                    })}
                    placeholder="Select Category or Enter Your Own"
                    onDropdownVisibleChange={handleDropdownVisibleChange(
                      `cat-${i}`,
                      "cat"
                    )}
                    onSelect={(value, isCustom) => handleSelectCategory(value, areaCategory?.id, i, isCustom)}
                    onCreate={handleCreateCategory([...predefinedAreas, ...customAreas].find((area) => area?.name === areaCategory?.name)?.id, [...predefinedAreas, ...customAreas]?.find((area) => area?.name === areaCategory?.name))}
                    onDelete={handleDeleteCategory([...predefinedAreas, ...customAreas].find((area) => area?.name === areaCategory?.name)?.id)}
                  />
                </Styles.RightSide>
              </Styles.ItemContent>

              <Styles.InfoWrapper>
                {areaCategory?.name && areaCategory?.goal?.category_name ? (
                  <SuggestingTooltip
                    open={isSuggestMissionStatement === 1 && i === firstItemWithoutMission}
                    color={areaCategory?.color}
                    onClick={shouldOpen => {
                      if (shouldOpen) {
                        setMissionStatementGoal({
                          category: areaCategory?.goal?.category_name,
                          area: areaCategory?.name,
                          priority: areaCategory?.goal?.priority,
                          goal: areaCategory?.goal?.id,
                          hasMissionStatement: areaCategory?.goal?.has_mission_statement,
                        });
                        setisShow(true);
                      }

                      setIsSuggestMissionStatement(2);
                    }}
                  >
                    <Styles.IconButton
                      title="Mission Statement"
                      onClick={() => {
                        setMissionStatementGoal({
                          category: areaCategory?.goal?.category_name,
                          area: areaCategory?.name,
                          priority: areaCategory?.goal?.priority,
                          goal: areaCategory?.goal?.id,
                          hasMissionStatement:
                            areaCategory?.goal?.has_mission_statement,
                          // hasMissionStatement: true,
                        });
                        setisShow(true);
                      }}
                      disabled={
                        !areaCategory?.name || !areaCategory?.goal?.category_name
                      }
                    >
                      {areaCategory?.goal?.has_mission_statement ? (
                        <img src={missionIconActive} alt="mission" />
                      ) : (
                        <img src={missionIcon} alt="mission" />
                      )}
                    </Styles.IconButton>
                  </SuggestingTooltip>
                ) : null}

                <Dropdown
                  secondary
                  menuItems={[
                    {
                      key: "private",
                      element: (
                        <Styles.DropdownItem>
                          Make Private
                          <Styles.SwitchWrapper>
                            <ReactSwitch
                              checked={areaCategory?.is_private}
                              checkedIcon={false}
                              uncheckedIcon={false}
                              onColor="#1689CA"
                              offColor="#cdcdcd"
                              handleDiameter={10}
                              height={15}
                              width={28}
                              onChange={() => hanlePvt(areaCategory)}
                            />
                          </Styles.SwitchWrapper>
                        </Styles.DropdownItem>
                      ),
                      hide: userData?.id !== areaCategory?.user,
                    },
                    {
                      key: "remove",
                      element: (
                        <Styles.DropdownButton>
                          Delete Row
                          <Trash />
                        </Styles.DropdownButton>
                      ),
                      disabled: isLocked || !(areasAndCategories?.length > 1),
                      onClick: () => deleteRow(areaCategory?.id),
                    },
                  ]}
                />
              </Styles.InfoWrapper>
            </Styles.Row>
          );
        })}

        <Styles.ActionsRow>
          <Styles.AddButton
            type="button"
            disabled={isDisabled || loading}
            onClick={handleAddMore}
          >
            Add More
            <img src={plusIcon} alt="+" />
          </Styles.AddButton>
        </Styles.ActionsRow>

        <PlanShortcut
          isVisible={masterPlanDetails?.viewed_masterplan_screen}
        />
      </Styles.TableContainer>

      {isShow && missionStatmentGoal && (
        <MissionStatement
          missionStatmentGoal={missionStatmentGoal}
          setMissionStatementGoal={setMissionStatementGoal}
          setisShow={setisShow}
        />
      )}

      <ModalContainer />
    </StepLayout>
  );
};

export default Mission;
